import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="frontpage" className="page_container">
          <div className="headerOuterWrapper">
            <div className="innerWrapper">
              <div className="dataWrapper">
                <div id="block-block-2" className="block block-block">
                  <div className="content">
                    <p>
                      <a title="Logo" className="siteLogo" href="/">
                        <img
                          alt="Migraleve"
                          src="/sites/migraleve.co.uk/files/images/logo.jpg"
                        />
                      </a>
                    </p>
                  </div>
                </div>
                <div className="trigger">
                  {" "}
                  <a>
                    <img src="/sites/migraleve.co.uk/files/images/menu_arrow.png" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="navOuterWrapper">
          <div className="innerWrapper">
            <div className="dataWrapper1">
              <div id="block-menu-primary-links" className="block block-menu">
                <div className="content">
                  <ul className="menu">
                    <li className="leaf first home">
                      <a href="/home" title="Home">
                        <img src="/sites/migraleve.co.uk/files/images/home.png" />
                      </a>
                    </li>
                    <li className="expanded aboutmigraines">
                      <a
                        href="/about-migraine"
                        title="About Migraines"
                        className="about-migraine"
                      >
                        ABOUT MIGRAINES
                      </a>
                      <ul className="menu">
                        <li className="leaf first migrainesymptoms">
                          <a
                            href="/migraine-symptoms"
                            title="Migraine Types & Symptoms"
                          >
                            Migraine Types & Symptoms
                          </a>
                        </li>
                        <li className="leaf potentialmigrainecauses">
                          <a
                            href="/migraine-causes"
                            title="Potential Migraine Causes"
                          >
                            Potential Migraine Causes
                          </a>
                        </li>
                        <li className="leaf last whatcanyoudoaboutit">
                          <a
                            href="/migraine-treatment-and-prevention"
                            title="Treatment and Relief"
                          >
                            Treatment and Relief
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="leaf active-trail ourproducts">
                      <a
                        href="/migraine-relief-products"
                        title="Our Products"
                        className="active"
                      >
                        OUR
                        <br /> PRODUCTS
                      </a>
                    </li>
                    <li className="leaf migrainemanagementtips">
                      <a href="/migraine-tips" title="Migraine Management Tips">
                        MIGRAINE MANAGEMENT TIPS
                      </a>
                    </li>
                    <li className="leaf migrainetriggerdiary">
                      <a
                        href="/migraine-trigger-diary"
                        title="Migraine Trigger Diary"
                      >
                        MIGRAINE TRIGGER DIARY
                      </a>
                    </li>
                    <li className="leaf last wheretobuy">
                      <a href="/where-to-buy" title="Where to buy">
                        WHERE TO BUY
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="contentOuterWrapper">
          <div className="innerWrapper">
            <div className="dataWrapper">
              <div className="homeBannerWrapper">
                <div className="innerPageContentWrapper">
                  <div className="innerPageContent">
                    <div id="node-9" className="node">
                      <div className="content clear-block">
                        <h1>MIGRAINE RELIEF PRODUCTS</h1>
                        <p>
                          If you suffer from migraines, you may need to consult
                          your doctor if you can't find an effective treatment
                          that works for you. You may need to try different
                          types of combinations of medicines before you find the
                          most effective ones.
                        </p>
                        <p>
                          Once you've found a treatment, or a combination of
                          treatments, that work well, make sure you always have
                          them handy so you can treat your migraine at the first
                          sign of an attack
                        </p>
                      </div>
                      <div className="clear-block">
                        <div className="meta"></div>
                      </div>
                    </div>
                    <div
                      id="block-views-products_display-block_1"
                      className="block block-views"
                    >
                      <div className="content">
                        <div className="view view-products-display view-id-products_display view-display-id-block_1 view-dom-id-1">
                          <div className="view-content">
                            <div className="views-row views-row-1 views-row-odd views-row-first">
                              <div className="position-relative">
                                <div id="slider">
                                  <button className="control_next">
                                    <img src="/sites/migraleve.co.uk/files/images/next.gif" />
                                  </button>
                                  <button className="control_prev">
                                    <img src="/sites/migraleve.co.uk/files/images/prev.gif" />
                                  </button>
                                  <ul>
                                    <li>
                                      <img src="/sites/migraleve.co.uk/files/images/MIG_COMP_24s_3D_T.jpg" />
                                    </li>
                                    <li>
                                      <img src="/sites/migraleve.co.uk/files/images/Migraleve_16_Pink_8_Yellow_Tablets_complete.jpg" />
                                    </li>
                                    <li>
                                      <img src="/sites/migraleve.co.uk/files/images/MIG_COMP_24s_3D_T.jpg" />
                                    </li>
                                    <li>
                                      <img src="/sites/migraleve.co.uk/files/images/Migraleve_16_Pink_8_Yellow_Tablets_complete.jpg" />
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <h3 className="product_title">MIGRALEVE™</h3>

                              <div className="views-field-nothing">
                                <span className="field-content">
                                  <div className="">
                                    <p>
                                      <strong>MIGRALEVE™</strong> contains both
                                      MIGRALEVE™ Pink Tablets and MIGRALEVE™
                                      Yellow Tablets to combat the different
                                      stages of a migraine attack. Contains
                                      paracetamol, buclizine and codeine.
                                    </p>
                                    <p>
                                      For three days use only. Can cause
                                      addiction. This medicine can make you feel
                                      sleepy. Do not drive while taking this
                                      medicine until you know how it makes you
                                      feel.
                                    </p>
                                    <p>This medicine contains paracetamol. Do not take anything else containing paracetamol while taking this medicine.</p>
                                    <p>
                                      Available in boxes of 12 and 24 tablets.
                                      Ask your pharmacist for more detail
                                    </p>
                                  </div>
                                </span>
                              </div>
                            </div>
                            <div className="views-row views-row-2 views-row-even">
                              <div id="slider1">
                                <button className="control_next1">
                                  <img src="/sites/migraleve.co.uk/files/images/next.gif" />
                                </button>
                                <button className="control_prev1">
                                  <img src="/sites/migraleve.co.uk/files/images/prev.gif" />
                                </button>
                                <ul>
                                  <li>
                                    <img src="/sites/migraleve.co.uk/files/images/MIG_PINK_24s_3D_T.jpg" />
                                  </li>
                                  <li>
                                    <img src="/sites/migraleve.co.uk/files/images/Migraleve_Pink_24_Tablets_Pink.jpg" />
                                  </li>
                                  <li>
                                    <img src="/sites/migraleve.co.uk/files/images/MIG_PINK_24s_3D_T.jpg" />
                                  </li>
                                  <li>
                                    <img src="/sites/migraleve.co.uk/files/images/Migraleve_Pink_24_Tablets_Pink.jpg" />
                                  </li>
                                </ul>
                              </div>
                              <h3 className="product_title">MIGRALEVE™ PINK</h3>

                              <div className="views-field-nothing">
                                <span className="field-content">
                                  <div className="">
                                    <p>
                                      <strong>MIGRALEVE™ Pink</strong> contains
                                      paracetamol and codeine, to treat your
                                      head pain, together with buclizine, to
                                      relieve nausea. If taken at the first sign
                                      of a migraine, MIGRALEVE™ Pink can prevent
                                      an attack from developing further. If
                                      further treatment is required, this can be
                                      followed with MIGRALEVE™ Yellow.
                                    </p>
                                    <p>
                                      For three days use only. Can cause
                                      addiction. This medicine can make you feel
                                      sleepy. Do not drive while taking this
                                      medicine until you know how it makes you
                                      feel.
                                    </p>
                                    <p>This medicine contains paracetamol. Do not take anything else containing paracetamol while taking this medicine.</p>
                                    <p>
                                      Available in boxes of 12 and 24 tablets.
                                      Ask your pharmacist for more detail.
                                    </p>
                                  </div>
                                </span>
                              </div>
                            </div>
                            <div className="views-row views-row-3 views-row-odd views-row-last">
                              <div id="slider2">
                                <button className="control_next2">
                                  <img src="/sites/migraleve.co.uk/files/images/next.gif" />
                                </button>
                                <button className="control_prev2">
                                  <img src="/sites/migraleve.co.uk/files/images/prev.gif" />
                                </button>
                                <ul>
                                  <li>
                                    <img src="/sites/migraleve.co.uk/files/images/MIG_YELLOW_24s_3D_T.jpg" />
                                  </li>
                                  <li>
                                    <img src="/sites/migraleve.co.uk/files/images/Migraleve_Yellow_24_Tables.jpg" />
                                  </li>
                                  <li>
                                    <img src="/sites/migraleve.co.uk/files/images/MIG_YELLOW_24s_3D_T.jpg" />
                                  </li>
                                  <li>
                                    <img src="/sites/migraleve.co.uk/files/images/Migraleve_Yellow_24_Tables.jpg" />
                                  </li>
                                </ul>
                              </div>
                              <h3 className="product_title">
                                MIGRALEVE™ YELLOW
                              </h3>
                              <div className="views-field-nothing">
                                <span className="field-content">
                                  <div className="">
                                    <p>
                                      <strong>MIGRALEVE™ Yellow</strong>{" "}
                                      contains paracetamol and codeine, which
                                      treat your continuing headache symptoms.
                                      This should only be taken after the first
                                      dose of MIGRALEVE™ Pink.
                                    </p>
                                    <p>
                                      For three days use only. Can cause
                                      addiction. This medicine can make you feel
                                      sleepy. Do not drive while taking this
                                      medicine until you know how it makes you
                                      feel.
                                    </p>
                                    <p>This medicine contains paracetamol. Do not take anything else containing paracetamol while taking this medicine.</p>
                                    <p>
                                      Available in boxes of 24 tablets. Ask your
                                      pharmacist for more detail.
                                    </p>
                                  </div>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="view-footer">
                            <h2>Dosage Instructions:</h2>
                            <p>
                              <strong>Children under 12 years:</strong>
                              <br />
                              This medicine is not recommended for children
                              under 12 years old. Consult your doctor.
                            </p>
                            <p>
                              <strong>Children 12 - 15 years:</strong>
                              <br />
                              Swallow <strong>1 Migraleve Pink</strong> tablet
                              at the first sign of a migraine attack. If the
                              migraine persists, then take{" "}
                              <strong>1 Migraleve Yellow</strong> tablet 4 hours
                              after the Pink dose and then every 4 hours.
                              <br />
                              <strong>Do not</strong> take more than 4 tablets
                              (1 pink and 3 yellow) in a 24 hour period. If
                              symptoms persist for more than 3 days, talk to
                              your doctor.{" "}
                            </p>
                            <p>
                              <strong>
                                Adults and Children 16 years and over:
                              </strong>{" "}
                              Swallow <strong>2 Migraleve Pink</strong> tablets
                              at the first sign of a migraine attack. If the
                              migraine persists, then take{" "}
                              <strong>2 Migraleve Yellow</strong> tablets 4
                              hours after the Pink dose and then every 4 hours
                              <br />
                              <strong>Do not</strong> take more than 8 tablets
                              (2 pink and 6 yellow) in a 24 hour period. If
                              symptoms persist for more than 3 days, talk to
                              your doctor.{" "}
                            </p>
                            <h2>Remember:</h2>
                            <ul className="pointer">
                              <li>
                                Do not take less than four hours after taking
                                other painkillers.
                              </li>
                              <li>Always start with Migraleve Pink tablets.</li>
                              <li>Swallow tablets with a glass of water.</li>
                              <li>
                                Do not take more than the stated dose above.
                              </li>
                              <li>This medicine contains paracetamol. Do not take anything else containing paracetamol while taking this medicine.</li>
                            </ul>
                            <p>
                              This medicine should not be taken for more than 3
                              days. If the pain does not improve after 3 days,
                              talk to your doctor for advice.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="innerPagePromo">
                    <div id="block-block-4" className="block block-block">
                      <div className="content">
                        <div className="migrane_tips">
                          <div className="inner">
                            <img
                              src="/sites/migraleve.co.uk/files/images/ma.png"
                              alt
                            />
                            <div>
                              <h2>tips</h2>
                              <p>
                                Get some useful tips to help prevent a migraine
                                attack.
                              </p>
                              <a href="/tips" title="Tips">
                                Find out more
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="where_to_buy">
                          <div className="inner">
                            <img
                              src="/sites/migraleve.co.uk/files/images/am.png"
                              alt
                            />
                            <div>
                              <h2>About migraines</h2>
                              <p>
                                What is a migraine? What causes it? What can you
                                do about it?
                              </p>
                              <a
                                href="/migraine-symptoms"
                                title="Get the answers"
                              >
                                Get the answers
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="buying_migraleve">
                          <div className="inner">
                            <img
                              src="/sites/migraleve.co.uk/files/images/bm.png"
                              alt
                            />
                            <div>
                              <h2>
                                buying migraleve<sup>™</sup>
                              </h2>
                              <p>
                                Find your nearest stockist of Migraleve
                                products.
                              </p>
                              <a href="/where-to-buy" title="Where to buy">
                                Where to buy
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="trigger_diaryBg">
                          <div className="inner">
                            <img
                              src="/sites/migraleve.co.uk/files/images/td.png"
                              alt
                            />
                            <div>
                              <h2>trigger diary</h2>
                              <p>
                                Want to find out what triggers your migraines?
                              </p>
                              <a
                                href="/trigger-diary"
                                title="Try a trigger diary"
                              >
                                Try a trigger diary
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footerOuterWrapper">
          <div className="innerWrapper">
            <div className="dataWrapper">
              <div className="footerContent">
                <div
                  id="block-menu-menu-site-footer-menu"
                  className="block block-menu"
                >
                  <div className="content">
                    <ul className="menu">
                      <li className="leaf first privacypolicy">
                        <a href="/privacy-policy" title="Privacy policy">
                          Privacy policy
                        </a>
                      </li>
                      <li className="leaf legalnotice">
                        <a href="/legal-notice" title="Legal notice">
                          Legal notice
                        </a>
                      </li>
                      <li className="leaf contactus">
                        <a href="/contact-us" title="Contact us">
                          Contact us
                        </a>
                      </li>
                      <li className="leaf cookiepolicy">
                        <a href="/cookie-policy" title="Cookie Policy">
                          Cookie Policy
                        </a>
                      </li>
                      <li className="leaf modernslaveryactstatement">
                        <a
                          href="/sites/migraleve.co.uk/files/pdf/MSA_JnJ_Limited_May_2024.pdf"
                          target="_blank"
                        >
                          Modern Slavery Act Statement
                        </a>
                      </li>
                      <li className="leaf last cookiesettings">
                        <a id="ot-sdk-btn" className="ot-sdk-show-settings">
                          Cookie Settings
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div id="block-block-3" className="block block-block">
                  <div className="content">
                    <p>
                      <strong>
                        Always read the label. Ask your pharmacist for advice.
                      </strong>
                    </p>
                    <p>
                      MIGRALEVE<em>™</em> contains paracetamol, buclizine and
                      codeine. MIGRALEVE<em>™</em> Pink contains paracetamol,
                      codeine and buclizine. MIGRALEVE<em>™</em> Yellow contains
                      paracetamol and codeine.
                    </p>
                    <p>
                      Can cause addiction. For three days use only. This
                      medicine can make you feel sleepy. Do not drive while
                      taking this medicine until you know how it makes you feel.
                    </p>
                    <p>
                      This site is published by Johnson &amp; Johnson Limited
                      which is solely responsible for its contents. It is
                      intended for a UK audience. MIGRALEVE<em>™</em> is a
                      registered trademark.
                    </p>
                    <p>
                      This site is best viewed with the following web browsers:
                      Internet Explorer version 8, Firefox version 2+, Safari
                      3+, Chrome version 3+.
                    </p>
                    <p>
                      © Johnson &amp; Johnson Limited 2024. This site was last
                      updated on: 15<sup>th </sup>August 2024
                    </p>
                  </div>
                </div>
                <p>
                  <a
                    id="popup-trigger"
                    href="/sites/migraleve.co.uk/files/pop-up-text"
                    rel="lightmodal"
                  >
                    hide this
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Page;
